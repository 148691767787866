import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import VideoCarousel from "../components/video_carousel";
import ImageCarousel from '../components/image_carousel';
import { Title } from '../components/components';
import govImg from '../assets/gouvenrure.jpg';
import Slider from 'react-slick';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

// Main HomePage Component
export default function HomePage() {
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetch('https://api.sitewebbcm.com/api/Monies?populate=*&pagination[limit]=-1')
      .then(response => response.json())
      .then((data) => setCurrencies(data.data))
      .catch(error => console.error('Error fetching currencies:', error));
  }, []);

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className='flex items-center gap-2'>
        <h1 className="font-Montserrat text-primary text-xs font-semibold uppercase lg:text-2xl">
          {t('Cours de change du')}
        </h1>
        <h1 className="font-Montserrat text-black text-xs font-semibold uppercase lg:text-2xl">
          {new Date().toLocaleDateString()}
        </h1>
        <h1 className="font-Montserrat text-primary text-xs font-semibold uppercase lg:text-2xl">
          {t('au')}
        </h1>
        <h1 className="font-Montserrat text-black text-xs font-semibold uppercase lg:text-2xl">
          {new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString()}
        </h1>
      </div>

      <div className='flex gap-2 items-center w-full'>
        <MovingCurrencyValues currencies={currencies} />
        <a href="/money-rate-table" className="text-primary underline underline-offset-2 text-xs font-bold w-28">
          {t('Voir détails')}
        </a>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3">
          <ActuSection />
        </div>
        <div className={`lg:w-1/3 mt-6 lg:mt-0 ${isRTL ? 'mr-4' : 'ml-4'}`}>
          <SearchSection />
        </div>
      </div>
    </div>
  );
}


const NewsGrid = ({ news }) => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRTL = language === 'ar';

  if (news.length === 0) return null;

  const latestNews = news[0];
  const otherNews = news.slice(1, 7); // Get next 6 news items

  return (
    <div className="bg-white border border-gray-300 w-full p-3 rounded-lg shadow-md">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* Latest News - Left Side */}
        <div className="flex flex-col lg:space-x-6">
          <div className="w-full">
            <img
              src={'https://api.sitewebbcm.com' + latestNews.attributes?.thumbnail_image?.data.attributes?.url}
              alt="news"
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>
          <div className="mt-3 space-y-3">
            <span className="text-xs">{new Date(latestNews.attributes.createdAt).toLocaleDateString()}</span>
            <h2 className="text-xs font-bold">
              {language === 'fr' ? latestNews.attributes.title_fr : latestNews.attributes.title_ar}
            </h2>
            <p className="text-xs">
              {language === 'fr' 
                ? latestNews.attributes.content_fr.slice(0, 200) 
                : latestNews.attributes.content_ar.slice(0, 200)}...
            </p>
            <button
              className="text-primary flex items-center space-x-2 text-xs"
              onClick={() => navigate(`/news/${latestNews.id}`)}
            >
              <span className="font-bold">{t('Lire la suite')}</span>
              <ArrowIcon direction={isRTL ? 'left' : 'right'} className="w-3 h-3" />
            </button>
          </div>
        </div>

        {/* Other News - Right Side Grid */}
        <div className="grid grid-cols-2 gap-4">
          {otherNews.map((item, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg p-2 border border-gray-100 flex flex-col justify-between h-[200px]"
            >
              <div>
                <img
                  src={'https://api.sitewebbcm.com' + item.attributes?.thumbnail_image?.data.attributes?.url}
                  alt={item.attributes.title_fr}
                  className="w-full h-20 object-cover rounded-lg mb-2"
                />
                <span className="text-xs text-gray-500 block">
                  {new Date(item.attributes.createdAt).toLocaleDateString()}
                </span>
                <h3 className="text-xs font-medium line-clamp-2 mt-1">
                  {language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
                </h3>
              </div>
              <button
                className="text-primary flex items-center gap-x-2 text-xs mt-2"
                onClick={() => navigate(`/news/${item.id}`)}
              >
                {t('Lire la suite')}
                <ArrowIcon direction={isRTL ? 'left' : 'right'} className="w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


// ActuSection Component
function ActuSection() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const isRTL = language === 'ar';
  const [news, setNews] = useState([]);
  const [communiques, setCommuniques] = useState([]);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newsResponse = await fetch('https://api.sitewebbcm.com/api/news?populate=*');
        const newsData = await newsResponse.json();
        setNews(newsData.data);

        const communiquesResponse = await fetch('https://api.sitewebbcm.com/api/press-releases?filters[type]=Specefique&populate=*');
        const communiquesData = await communiquesResponse.json();
        setCommuniques(communiquesData.data);

        const videosResponse = await fetch(`https://api.sitewebbcm.com/api/home-medias?filters[type][$eq]=VIDEO&filters[language]=${language}&populate=*`);
        const videosData = await videosResponse.json();
        setVideos(videosData.data);

        const imagesResponse = await fetch(`https://api.sitewebbcm.com/api/home-medias?filters[type][$eq]=IMAGE&filters[language]=${language}&populate=*`);
        const imagesData = await imagesResponse.json();
        setImages(imagesData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [language]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status">
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <Title>{t('Actualités')}</Title>
        <Link to="/news" className="text-primary underline underline-offset-2 text-xs font-bold">
          {t('Tout l\'actualité')}
        </Link>
      </div>
      <div className="bg-white border border-gray-300 w-full p-3 rounded-lg shadow-md flex flex-col gap-2">
        {news.length > 0 && (
          <div className="hidden md:flex flex-col lg:flex-row lg:space-x-6">
            <div className="w-full lg:w-1/2 ml-4">
              <img
                src={'https://api.sitewebbcm.com' + news[0].attributes?.thumbnail_image?.data.attributes?.url}
                alt="news"
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>
            <div className="mt-3 lg:mt-0 lg:w-1/2 space-y-3">
              <span className="text-xs">{new Date(news[0].attributes.createdAt).toLocaleDateString()}</span>
              <h2 className="text-xs font-bold">{language === 'fr' ? news[0].attributes.title_fr : news[0].attributes.title_ar}</h2>
              <p className="text-xs">{language === 'fr' ? news[0].attributes.content_fr.slice(0, 200) : news[0].attributes.content_ar.slice(0, 200)}</p>
              <button
                className="text-primary flex items-center space-x-2 text-xs"
                onClick={() => navigate(`/news/${news[0].id}`)}
              >
                <span className="font-bold">{t('Lire la suite')}</span>
                <ArrowIcon direction={isRTL ? 'left' : 'right'} className="w-3 h-3" />
              </button>
            </div>
          </div>
        )}
        <NewsCarousel items={news} />
      </div>
      <div className="flex flex-col gap-2">
        <Title>{t('Médiathèque')}</Title>
        {videos.length > 0 && <VideoCarousel videos={videos} />}
      </div>
      <div className="lg:hidden">
        <GovernorSection />
      </div>
      <div className="lg:hidden">
        <AppelsDoffresSection />
      </div>
      <div className="flex flex-col gap-2 bg-white">


        <div className='flex justify-between items-center'>
        <Title>{t('Communiqués')}</Title>

        </div>

        <CommuniquesSlider communiques={communiques} />
{/* 
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {communiques.map((item, index) => (
            <SsSection
              key={index}
              title={language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
              items={item.attributes.press_relases_articles.data}
              icon={
                <svg width="16" height="16" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.8332 8.33334V10C12.8332 11.5471 12.2186 13.0308 11.1246 14.1248C10.0307 15.2188 8.54693 15.8333 6.99984 15.8333M6.99984 15.8333C5.45274 15.8333 3.96901 15.2188 2.87505 14.1248C1.78109 13.0308 1.1665 11.5471 1.1665 10V8.33334M6.99984 15.8333V19.1667M3.6665 19.1667H10.3332M6.99984 0.833344C6.3368 0.833344 5.70091 1.09674 5.23207 1.56558C4.76323 2.03442 4.49984 2.6703 4.49984 3.33334V10C4.49984 10.6631 4.76323 11.2989 5.23207 11.7678C5.70091 12.2366 6.3368 12.5 6.99984 12.5C7.66288 12.5 8.29876 12.2366 8.7676 11.7678C9.23645 11.2989 9.49984 10.6631 9.49984 10V3.33334C9.49984 2.6703 9.23645 2.03442 8.7676 1.56558C8.29876 1.09674 7.66288 0.833344 6.99984 0.833344Z" stroke="#25AE09" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }
            />
          ))}
        </div> */}
      </div>

      <div className="hidden lg:flex lg:flex-col lg:gap-2">
  <Title>{t('Photos')}</Title>
  <ImageCarousel images={images} />
</div>
    </div>
  );
}




function SearchSection() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const [coursData, setCoursData] = useState([]);
  const [startDate, setStartDate] = useState(getDefaultDates().start);
  const [endDate, setEndDate] = useState(getDefaultDates().end);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function getDefaultDates() {
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 1);
    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  }

  const fetchCoursData = async (start: string, end: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.sitewebbcm.com/api/cours-central-and-refrences?` + 
        `filters[date][$gte]=${start}&` +
        `filters[date][$lte]=${end}&` +
        `pagination[limit]=-1&` +
        `sort[0]=date:asc`
      );
      const data = await response.json();
      setCoursData(data.data);
    } catch (error) {
      console.error('Error fetching cours data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initial fetch with default dates
    fetchCoursData(startDate, endDate);

    // Fetch images data
    const fetchImages = async () => {
      const imagesResponse = await fetch(
        `https://api.sitewebbcm.com/api/home-medias?filters[type][$eq]=IMAGE&filters[language]=${language}&populate=*`
      );
      const imagesData = await imagesResponse.json();
      setImages(imagesData.data);
    }

    fetchImages();
  }, [language]);

  const handleDateChange = (event, dateType) => {
    const newDate = event.target.value;
    if (dateType === 'start') {
      setStartDate(newDate);
      fetchCoursData(newDate, endDate);
    } else {
      setEndDate(newDate);
      fetchCoursData(startDate, newDate);
    }
  };

  return (
    <div className="space-y-4">
      <div className='hidden lg:block'>
        <AppelsDoffresSection />
      </div>

      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">
            {t('Sélectionner la période')}
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t('Date de début')}
              </label>
              <input
                type="date"
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 text-sm outline-none hover:border-gray-300"
                value={startDate}
                onChange={(e) => handleDateChange(e, 'start')}
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t('Date de fin')}
              </label>
              <input
                type="date"
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 text-sm outline-none hover:border-gray-300"
                value={endDate}
                onChange={(e) => handleDateChange(e, 'end')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='border border-gray-300 flex-col p-2 rounded-lg gap-4 shadow-md'>
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-Montserrat text-primary text-base font-semibold uppercase">
            {t('Evolution du cours central')} USD/MRU
          </h2>
          {isLoading && (
            <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"/>
          )}
        </div>
        <CoursCentralChart data={coursData} type="central" />
      </div>

      <div className='border border-gray-300 flex-col p-2 rounded-lg shadow-md gap-4'>
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-Montserrat text-primary text-base font-semibold uppercase">
            {t('Evolution du cours de référence')} USD/MRU
          </h2>
          {isLoading && (
            <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"/>
          )}
        </div>
        <CoursReferenceChart data={coursData} type="refrence" />
      </div>

      <div className='hidden lg:block mt-4'>
        <GovernorSection />
      </div>
      
      <div className="flex flex-col gap-2 lg:hidden">
        <Title>{t('Photos')}</Title>
        <ImageCarousel images={images} />
      </div>
    </div>
  );
}

function CoursCentralChart({ data, type }) {
  const filteredData = data ? data
    ?.filter(item => item.attributes.type === type)
    ?.map(item => ({
      x: item.attributes.date,
      y: parseFloat(item.attributes.value)
    })) : [];


  const { t } = useTranslation();
  const chartData = {
    labels: filteredData.map(point => {
      const date = new Date(point.x);
      return date.toLocaleDateString();
    }),
    datasets: [
      {
        label: type === 'central' ? 'Cours Central' : 'Cours de Référence',
        data: filteredData.map(point => point.y),
        borderColor: '#00923f',
        backgroundColor: 'rgba(0, 146, 63, 0.1)',
        borderWidth: 2,
        tension: 0.4,
        fill: true,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#00923f',
        pointBorderWidth: 2,
        pointHoverBackgroundColor: '#00923f',
        pointHoverBorderColor: '#ffffff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          font: {
            family: 'Montserrat',
            size: 12,
            weight: '600',
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.98)',
        titleColor: '#1a1a1a',
        bodyColor: '#1a1a1a',
        bodyFont: {
          family: 'Montserrat',
          size: 12,
        },
        titleFont: {
          family: 'Montserrat',
          size: 13,
          weight: '600',
        },
        padding: 12,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        displayColors: false,
        boxPadding: 6,
        cornerRadius: 8,
        callbacks: {
          title: (tooltipItems) => {
            const date = new Date(tooltipItems[0].label);
            return date.toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            });
          },
          label: (context) => {
            return `Valeur: ${context.raw.toFixed(5)}`;
          }
        }
      }
    },
    scales: {
      y: {
        min: Math.min(...filteredData.map(point => point.y)) - 0.001,
        max: Math.max(...filteredData.map(point => point.y)) + 0.001,
        ticks: {
          stepSize: 0.00001,
          callback: function(value) {
            return value.toFixed(5);
          },
          font: {
            family: 'Montserrat',
            size: 11,
          },
          padding: 8,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: {
            family: 'Montserrat',
            size: 11,
          },
          padding: 8,
        },
        grid: {
          display: false,
        },
      }
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    animation: {
      duration: 1000,
      easing: 'easeInOutQuart',
    },
  };

  if (filteredData.length === 0) {
    return (
      <div className="w-full h-64 lg:h-80 bg-gray-50 rounded-lg border border-gray-100 flex items-center justify-center">
        <div className="text-center p-6">
          <div className="mx-auto h-12 w-12 text-gray-400 mb-4">
            <svg className="h-full w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
            </svg>
          </div>
          <h3 className="text-sm font-medium text-gray-900">
            {t('Aucune donnée disponible')}</h3>
          {/* <p className="mt-2 text-sm text-gray-500">Aucune donnée n'a été trouvée pour la période sélectionnée</p> */}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Stats Cards */}
      {/* <div className="grid grid-cols-3 gap-4">
        <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
          <div className="flex items-center justify-between">
            <div className="h-8 w-8 rounded-full bg-green-100 flex items-center justify-center">
              <svg className="h-4 w-4 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
              </svg>
            </div>
            <span className="text-xs font-medium text-gray-500">Minimum</span>
          </div>
          <p className="mt-2 text-lg font-semibold text-gray-900">
            {Math.min(...filteredData.map(point => point.y)).toFixed(5)}
          </p>
        </div>
        <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
          <div className="flex items-center justify-between">
            <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
              <svg className="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
            </div>
            <span className="text-xs font-medium text-gray-500">Maximum</span>
          </div>
          <p className="mt-2 text-lg font-semibold text-gray-900">
            {Math.max(...filteredData.map(point => point.y)).toFixed(5)}
          </p>
        </div>
        <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
          <div className="flex items-center justify-between">
            <div className="h-8 w-8 rounded-full bg-purple-100 flex items-center justify-center">
              <svg className="h-4 w-4 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <span className="text-xs font-medium text-gray-500">Moyenne</span>
          </div>
          <p className="mt-2 text-lg font-semibold text-gray-900">
            {(filteredData.reduce((acc, curr) => acc + curr.y, 0) / filteredData.length).toFixed(5)}
          </p>
        </div>
      </div> */}

      {/* Chart */}
      <div className="bg-white rounded-lg p-4 h-[400px]">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
}

// Use the same component for Reference chart
const CoursReferenceChart = CoursCentralChart;


// GovernorSection Component
function GovernorSection() {
  const { t } = useTranslation();

  return (
    <div className='border border-gray-300 flex-col p-2 rounded-lg shadow-md gap-4 bg-white'>
      <Title>{t('Le Gouverneur')}</Title>
      <div className='flex flex-col items-center justify-center gap-2 mt-2'>
        <img src={govImg} alt="" />
        <h3 className='font-bold text-lg'>
          {t('MOHAMED LEMINE DHEHBY')}
        </h3>
        <span>{t('Né le 09/05/1966 à Zouerate.')}</span>
        <span className='text-sm'>{t('gov-presenation')}</span>
      </div>
    </div>
  );
}

// AppelsDoffresSection Component
function AppelsDoffresSection() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const [offersCall, setOffersCall] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://api.sitewebbcm.com/api/offers-calls?populate=*&pagination[limit]=-1')
      .then(response => response.json())
      .then((data) => {
        setOffersCall(data.data);
      })
      .catch(error => console.error('Error fetching offers-call:', error));
    }, []);
  
    return (
      <div className="bg-white border border-gray-300 p-3 rounded-lg shadow-md">
        <div className="flex justify-between items-center">
          <Title>{t('Appelsoffers')}</Title>
          <a onClick={() => navigate('/offers-calls')} className="text-primary cursor-pointer  underline underline-offset-2 text-xs font-bold">
            {t('Voir Tout')}
          </a>
        </div>
        <div className="flex flex-col gap-y-3 mt-3">
          {offersCall?.slice(0, 3)?.map((item, index) => (
            <div key={index} className="flex gap-1 items-center justify-between">
              <div className="flex gap-1 items-center">
                <svg width="12" height="12" viewBox="0 0 16 16" fill="#25AE09" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="4" fill="#25AE09" />
                </svg>
                <h2 className="text-xs font-bold">{language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}</h2>
              </div>
              <button className="text-primary flex items-center space-x-2 text-xs" onClick={() => navigate(`/offers-calls/${item.id}`)}>
                {t('Lire la suite')}
                <ArrowIcon direction={language === 'fr' ? "right" : "left"} className="w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export function ArrowIcon({
    direction = "left",
    className = "",
  }) {
    return (
      <svg
        width="14"
        className={`cursor-pointer stroke-[#2C3136] stroke-2 ${direction === "right" ? "rotate-180 transform" : ""} ${className}`}
        height="26"
        viewBox="0 0 14 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1L1 13L13 25"
          stroke="inherit"
          strokeWidth="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  


  function MoneyChartCentral({ currency, startDate, endDate }) {
    const { language } = useLanguage();
    const dataPoints = currency.attributes.money_today_changes.data
      .filter((change) => change.attributes.type === 'REFERENCE')
      .filter((change) => {
        const changeDate = new Date(change.attributes.day);
        return (!startDate || changeDate >= new Date(startDate)) && 
               (!endDate || changeDate <= new Date(endDate));
      })
      .map(change => ({
        x: change.attributes.day,
        y: parseFloat(change.attributes.value),
      }));
  
    const data = {
      labels: dataPoints.map(point => point.x),
      datasets: [
        {
          label: language === 'fr' ? currency.attributes.name_fr : currency.attributes.name_ar,
          data: dataPoints.map(point => point.y),
          borderColor: '#00923f',
          backgroundColor: 'rgba(90, 128, 79 , 1)',
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: language === 'fr' ? currency.attributes.name_fr : currency.attributes.name_ar,
        },
      },
      scales: {
        y: {
          min: Math.min(...dataPoints.map(point => point.y)) - 0.01,
          max: Math.max(...dataPoints.map(point => point.y)) + 0.01,
          ticks: {
            stepSize: 0.00001,
          },
        },
      },
    };
  
    return (
      <div className="w-full h-56 lg:h-64 bg-white">
        <Line options={options} data={data} />
      </div>
    );
  }
  
  function MoneyChartInterBancaire({ currency, startDate, endDate }) {
    const { language } = useLanguage();
    const dataPoints = currency.attributes.money_today_changes.data
      .filter((change) => change.attributes.type === 'INTERBANK')
      .filter((change) => {
        const changeDate = new Date(change.attributes.day);
        return (!startDate || changeDate >= new Date(startDate)) && 
               (!endDate || changeDate <= new Date(endDate));
      })
      .map(change => ({
        x: change.attributes.day,
        y: parseFloat(change.attributes.value),
      }));
  
    const data = {
      labels: dataPoints.map(point => point.x),
      datasets: [
        {
          label: language === 'fr' ? currency.attributes.name_fr : currency.attributes.name_ar,
          data: dataPoints.map(point => point.y),
          borderColor: '#00923f',
          backgroundColor: 'rgba(90, 128, 79 , 1)',
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: language === 'fr' ? currency.attributes.name_fr : currency.attributes.name_ar,
        },
      },
      scales: {
        y: {
          min: Math.min(...dataPoints.map(point => point.y)) - 0.01,
          max: Math.max(...dataPoints.map(point => point.y)) + 0.01,
          ticks: {
            stepSize: 0.00001,
          },
        },
      },
    };
  
    return (
      <div className="w-full h-56 lg:h-64 bg-white">
        <Line options={options} data={data} />
      </div>
    );
  }
  
  // MovingCurrencyValues Component
  function MovingCurrencyValues({ currencies }) {
    const { language } = useLanguage();
    const isRTL = language === 'ar';
  
    const getTodayValue = (money) => {
      const today = new Date().toISOString().split('T')[0];
      const todayChange = money.attributes.money_today_changes.data.find(change => change.attributes.day === today);
      return todayChange ? todayChange.attributes.value : 'N/A';
    };
  
    return (
      <div className="bg-gradient-to-r from-primary via-[#007934] to-primary text-white overflow-hidden py-1 my-3 rounded-md w-full">
        <div className={`w-full flex gap-2 animate-marquee ${isRTL ? 'animate-marquee-rtl' : ''}`}>
          {currencies.map((currency, index) => (
            <div key={index} className='flex  items-center justify-center'>
              <span className="text-xs font-bold w-28">
                {currency.attributes.code + ' : ' + getTodayValue(currency) + '   '}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  // NewsCarousel Component
 

  function NewsCarousel({ items }) {
    const { language } = useLanguage();
    const isRTL = language === 'ar';
    const { t } = useTranslation();
    const navigate = useNavigate();
  
    const navigateToDetail = (id) => {
      navigate(`/news/${id}`);
    };
  
    return (
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {items.map((item, index) => (
            <div key={index} className="w-full h-full">
              <div className="bg-white rounded-lg shadow-md p-3 h-full flex flex-col">
                <div className="flex flex-col gap-3 flex-grow">
                  <div className="w-full">
                    <img 
                      src={'https://api.sitewebbcm.com' + item.attributes?.thumbnail_image?.data?.attributes?.url} 
                      alt={item.attributes.title_fr} 
                      className="w-full h-32 object-cover rounded-lg" 
                    />
                  </div>
                  <div className="w-full flex-grow">
                    <span className="text-xs text-gray-500 block mb-2">
                      {new Date(item.attributes.createdAt).toLocaleDateString()}
                    </span>
                    {item.attributes.content_fr && 
                      <p className="text-xs text-gray-700 line-clamp-3">
                        {language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
                      </p>
                    }
                  </div>
                  <button 
                    className="text-primary flex items-center gap-x-2 text-xs mt-auto" 
                    onClick={() => navigateToDetail(item.id)}
                  >
                    {t('Lire la suite')}
                    <svg width="6" height="10" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path 
                        d="M1.75 10.5L6.25 6L1.75 1.5" 
                        stroke="#25AE09" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  // SsSection Component





  function CommuniquesSlider({ communiques }) {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { t } = useTranslation();
    const [slideHeight, setSlideHeight] = useState(0);
    const sliderRef = useRef(null);
  
    useEffect(() => {
      const updateHeight = () => {
        if (sliderRef.current) {
          const slides = sliderRef.current.querySelectorAll('.slick-slide');
          let maxHeight = 0;
          slides.forEach(slide => {
            const height = slide.offsetHeight;
            maxHeight = Math.max(maxHeight, height);
          });
          setSlideHeight(maxHeight);
        }
      };
  
      updateHeight();
      window.addEventListener('resize', updateHeight);
  
      return () => window.removeEventListener('resize', updateHeight);
    }, [communiques]);
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
  
    return (
      <div ref={sliderRef}>
        <Slider {...settings}>
          {communiques.map((item, index) => (
            <div key={index} className="px-2">
              <SsSection
                parentID={item.id}
                title={language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
                items={item.attributes.press_relases_articles.data.slice(0, 1)}
                icon={
                  <svg width="16" height="16" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8332 8.33334V10C12.8332 11.5471 12.2186 13.0308 11.1246 14.1248C10.0307 15.2188 8.54693 15.8333 6.99984 15.8333M6.99984 15.8333C5.45274 15.8333 3.96901 15.2188 2.87505 14.1248C1.78109 13.0308 1.1665 11.5471 1.1665 10V8.33334M6.99984 15.8333V19.1667M3.6665 19.1667H10.3332M6.99984 0.833344C6.3368 0.833344 5.70091 1.09674 5.23207 1.56558C4.76323 2.03442 4.49984 2.6703 4.49984 3.33334V10C4.49984 10.6631 4.76323 11.2989 5.23207 11.7678C5.70091 12.2366 6.3368 12.5 6.99984 12.5C7.66288 12.5 8.29876 12.2366 8.7676 11.7678C9.23645 11.2989 9.49984 10.6631 9.49984 10V3.33334C9.49984 2.6703 9.23645 2.03442 8.7676 1.56558C8.29876 1.09674 7.66288 0.833344 6.99984 0.833344Z" stroke="#25AE09" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                }
                onViewAll={() => navigate(`/press-releases/${item.id}`)}
                height={slideHeight}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
  
  function SsSection({ title, icon, items, onViewAll, height  , parentID}) {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { t } = useTranslation();
  
    return (
      <div 
        className="border border-gray-300 p-3 rounded-lg shadow-md flex flex-col justify-between"
        style={{ height: height ? `${height}px` : 'auto' }}
      >
        <div>
          <h3 className="font-Montserrat mb-2 flex gap-x-[8px] text-xs font-bold">
            {icon}
            {title}
          </h3>
          {items.map((item, index) => (
            <div className="flex flex-col gap-y-[5px]" key={index}>
              <h2 className="text-black text-xs">
                {language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
              </h2>
              <button 
                className="text-primary flex items-center gap-x-2 text-xs" 
                onClick={() => navigate(`/press-release/${item.id}`)}
              >
                {t('Lire la suite')}
                <ArrowIcon direction={language === 'fr' ? "right" : "left"} className="w-3 h-3 stroke-primary" />
              </button>
            </div>
          ))}
        </div>

        <div className='flex items-center justify-center'>

        <Link 
          className="mt-2 text-primary underline underline-offset-2 text-xs font-bold"


          to={`press-releases/${parentID}`}
        >
          {t('Voir tout')}
        </Link>
        </div>
      
      </div>
    );
  }




  const CustomPrevArrow = ({ onClick }) => (
    <button
      className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-1 rounded-r"
      onClick={onClick}
    >
      <ArrowIcon className="stroke-white" />
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-1 rounded-l"
      onClick={onClick}
    >
      <ArrowIcon direction="right" className="stroke-white" />
    </button>
  );
